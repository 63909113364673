<template>
  <div class="doc-write">
    
    <div v-if="!signed" class="lg-wrap">
      <div class="lg-bg">
        <div>
          <input
          type="text"
          name="username"
          v-model="user.username"
          placeholder="请输入账号"
          @keyup.enter="login">
        </div>
        <div>
          <input
            type="password"
            name="password"
            v-model="user.password"
            placeholder="请输入密码"
            @keyup.enter="login">
        </div>
        <div>
          <button @click="login">登录</button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="model-wrap">
        <img class="wrt-logo" src="@/assets/images/logo.png" @click.self="backHome"/>
          <div class="model-wrap-scroll">
              <div
                  v-for="(item, i) in models"
                  :key="i"
                  :class="{'active': item.model_id == curr_model}"
                  @click="changeModel(item.model_id)"
                  class="md-btn"
              >{{item.model_name}} <i class="icon-opt icon-edit" @click.stop="editModel(item)" ><svg class="iconfont-edit" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12179" width="16" height="16"><path d="M550.4 292.608l180.992 181.034667L308.992 896H128v-181.034667l422.4-422.4z m60.330667-60.330667l90.496-90.538666a42.666667 42.666667 0 0 1 60.330666 0l120.704 120.704a42.666667 42.666667 0 0 1 0 60.330666l-90.538666 90.496-180.992-180.992z" p-id="12180" style='fill: #bdbdbd'></path></svg></i>
              </div>
          </div>
<!--        <div class="select-language">
          <span class="cn" :style='lang==1?"opacity:1":"opacity:.4"' @click='setLau(1)'>中文</span> <i>|</i> 
          <span class="en" :style='lang==2?"opacity:1":"opacity:.4"' @click='setLau(2)'>EN</span>
        </div>-->
        <div class="add-model-btn" @click="createModel">+ 添加模块</div>
      </div>
      <div class="doc-body">
        <div class="doc-menus">
          <div v-if="show_dropdown" class="plus-dropdown" :style="{left: dropdown_left + 'px', top: dropdown_top + 'px'}" @mouseleave="leaveDropdown">
            <div class="dropdown-item" @click.stop="handleOperate(1)">子节点</div>
            <div class="dropdown-item" @click.stop="handleOperate(2)">向前添加</div>
            <div class="dropdown-item" @click.stop="handleOperate(3)">向后添加</div>
          </div>
          <div class="menu-wrap">
            <tree-menu
              v-if="menu_loaded"
              :active_hash_key="curr_hash_key"
              ref="tree_menu_root"
              title=""
              :children="menus"
              :layer="0"
              pos=""
              :type="2"
              :clickPlus="menuCreate"
              :clickEdit="menuEdit"
              :clickMove="menuMove"
              :clickDelete="menuDelete"
              :inputChange="newMenuInputChange"
              :clickMenu="menuChange">
            </tree-menu>
            <span class="txt-btn" v-if="models.length>0 && menu_loaded && menus.length == 0" @click="createFirstMenu">+ 添加文档</span>
          </div>
        </div>
        <div :class="['doc-container', editor_focus && 'is-focus']">
          <!-- <div id="ob-editor" class="my-editor"></div> -->
          <vueTinymce ref="vueTinymce" :currentContent='curr_doc' @changeTitle="changeTitle"></vueTinymce>
        </div>
        <div class="operation-bar">
          <div :class="['primary-btn', 'sm', !curr_doc.title && 'disabled']" @click="saveDoc">
            <span>发布测试</span>
          </div>
          <div :class="['primary-btn', 'sm', !curr_doc.title && 'disabled']" @click="publish" style="margin-left: 25px;">
            <span>发布正式</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vditor from 'vditor'
import Cookie from 'js-cookie'
import 'vditor/dist/index.css'
import marked from 'marked'
import treeMenu from '@/components/doc/tree-menu'
import vueTinymce from '@/components/doc/vue-tinymce'
import urls from '@/container/urls'
const ALERT_OPTION = {
  toast: true,
  position: 'top-center',
  showConfirmButton: false,
}
export default {
  components: {
    treeMenu,vueTinymce
  },
  data() {
    return {
      lang:1,
      models: [],
      menus: [],
      menu_loaded: false,
      curr_model: '',
      curr_hash_key: '',
      curr_doc: {
        title: '',
        updated_at: '',
        content: '',
        pid: ''
      },
      loading: true,
      loaded: false,
      signed: false,
      user: {
        username: '',
        password: ''
      },
      token: '',
      content: '',   
      vditor: null,
      editor_focus: false,
      is_editing_new: false,
      show_dropdown: false,
      dropdown_left: -999,
      dropdown_top: -999
    }
  },
  inject:['reload'],
  mounted() {
    let token = Cookie.get('__cd_vt')
    if(this.$route.query.lang){
      this.lang = this.$route.query.lang=='en'?2:1
    }
    if (token) {
      this.token = token
      this.signed = true
      this.$nextTick(() => {
        this.getModels()
        this.initEditor()
        document.body.addEventListener('click', this.bodyClick)
      })
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.bodyClick)
  },
  methods: {
      changeTitle(title){
          this.curr_doc.title = title
      },
    backHome(){
      this.$router.push('/')
    },
    async editModel(item){
      // await this.confirm('确定要删除吗？', '删除会将此节点及其所有子级节点删除', 'warning')
      this.$swal({
        title: '修改模块名',
        input: 'text',
        inputValue:item.model_name,
        inputAttributes: {
          placeholder: '请输入模块名',
          autocapitalize: 'off'
        },
        showCloseButton:true,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        showLoaderOnConfirm: true,
        preConfirm: (val) => {
          if (val) {
            let params = {
              model_name: val,
              model_id: item.model_id
            }
            return this.$axios.post(urls.update_model, params)
              .then(res => {
                if (res.header.code == 0) {
                  this.$swal({
                    ...ALERT_OPTION,
                    text: '修改成功',
                    timer: 500
                  })
                  this.getModels('onlyEdit')
                  return true
                }
                this.$swal.showValidationMessage(res.header.msg || '网络错误，请稍后重试')
              })
              .catch(err => {
                this.$swal.showValidationMessage('网络错误，请稍后重试')
              })
          } else {
            this.$swal.showValidationMessage('标题不可为空!')
          }
        }, allowOutsideClick: () => !this.$swal.isLoading()
      })
    },
    setLau(num){
      this.lang = num;
      let lang = num==2?"en":"cn"
      this.$router.push({path:this.$route.path,query:{...this.$route.query,lang},replace:false})
      // this.vditor.setValue(this.curr_doc.content='', true)
      // this.getModels('change')
      this.reload(true)
      
    },
    async login() {
      let { username, password } = this.user
      if (!username || !password) return;
      const { header, data } = await this.$axios.post(urls.login, { username, password })
      if (header.code == 0) {
        let al = this.$swal({
          ...ALERT_OPTION,
          text: '登录成功',
          timer: 500
        })
        this.token = data
        this.signed = true
        Cookie.set('__cd_vt', this.token, { expires: 1})
        let tim = setTimeout(() => {
          clearTimeout(tim)
          this.getModels()
          this.initEditor()
        }, 800);
      } else {
        this.$swal({
          toast: true,
          text: header.msg,
          timer: 2000
        })
      }
    },
    createModel() {
      this.$swal({
        title: '添加模块',
        input: 'text',
        inputAttributes: {
          placeholder: '请输入模块名称',
          autocapitalize: 'off'
        },
        customClass: {
          container: 'cn-modal'
        },
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        showLoaderOnConfirm: true,
        preConfirm: (val) => {
          if (val) {
            let params = { 
              model_name: val,
              summary: val
            }
            return this.$axios.post(urls.create_model, params)
              .then(res => {
                if (res.header.code == 0 && res.data) {
                  this.$swal({
                    ...ALERT_OPTION,
                    text: '添加成功',
                    timer: 500
                  })
                  this.getModels('onlyEdit')
                  // this.models.push({
                  //   model_name: val,
                  //   model_id: res.data.model_id,
                  //   summary: val
                  // })
                  return true
                }
                this.$swal.showValidationMessage(res.header.msg || '网络错误，请稍后重试')
              })
              .catch(err => {
                this.$swal.showValidationMessage('网络错误，请稍后重试')
              })
          } else {
            this.$swal.showValidationMessage('名称不可为空!')
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },
    changeModel(m) {
      this.is_editing_new = false
      if (m == this.curr_model) return
      this.curr_model = m
      this.updateMenu()
    },
    menuChange(menu) {
      if (menu.is_new || this.is_editing_new) return
      let hash_key = menu.hash_key
      if (hash_key == this.curr_hash_key) return;
      this.curr_hash_key = menu.hash_key
      this.getDoc()
    },
    menuDelete(menu) {
      this.toDel(menu)
    },
    async toDel(menu) {
      let delConfirm = await this.confirm('确定要删除吗？', '删除会将此节点及其所有子级节点删除', 'warning')
        if (!delConfirm) return
        if (menu.is_new) {
          this.is_editing_new = false
          this.updateMenu()
          this.getDoc()
          return
        }
        this.delDoc(menu)
    },
    menuCreate(e, pos, menu) {
      let bound = e.target.getBoundingClientRect()
      let left = bound.left
      this.dropdown_left = left
      this.dropdown_top = bound.bottom + 5
      this.show_dropdown = true
      this.curr_click_menu = menu
      this.curr_click_pos = pos
    },
    menuEdit(menu) {
      this.$swal({
        title: '修改标题',
        input: 'text',
        inputAttributes: {
          placeholder: '请输入新标题',
          autocapitalize: 'off'
        },
        customClass: {
          container: 'cn-modal'
        },
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        showLoaderOnConfirm: true,
        preConfirm: (val) => {
          if (val) {
            let params = {
              title: val,
              content: '',
              hash_key: menu.hash_key,
              model_id: menu.model_id
            }
            return this.$axios.post(urls.update_docs, params)
              .then(res => {
                if (res.header.code == 0) {
                  this.$swal({
                    ...ALERT_OPTION,
                    text: '修改成功',
                    timer: 500
                  })
                  this.updateMenu()
                  return true
                }
                this.$swal.showValidationMessage(res.header.msg || '网络错误，请稍后重试')
              })
              .catch(err => {
                this.$swal.showValidationMessage('网络错误，请稍后重试')
              })
          } else {
            this.$swal.showValidationMessage('标题不可为空!')
          }
        }, allowOutsideClick: () => !this.$swal.isLoading()
      })
    },
    menuMove(menu, pos, direction) {
      let iis = pos.split('-')
      let targetParent = this.menus
      let len = iis.length
      let targetIdx = +iis[len - 1]
      let params = {
        hash_key: menu.hash_key,
        // prev_id: 0
      }
      if (iis.length == 1) {
        if (direction == 1) {
          if (targetIdx == 0) return
          params.prev_id = targetParent[targetIdx - 1].id
        } else {
          if (targetIdx == targetParent.length - 1) return
          params.prev_id = menu.id
          params.hash_key = targetParent[targetIdx + 1].hash_key
        }
      } else {
        iis.forEach((idx, j) => {
          let i = +idx
          if (j < len - 1) {
            targetParent = j == 0 ? targetParent[i] : targetParent.children[i]
          }
        })
        if (direction == 1) {
          if (targetIdx == 0) return
          params.prev_id = targetParent.children[targetIdx - 1].id
        } else {
          if (targetIdx == targetParent.children.length - 1) return
          params.prev_id = menu.id
          params.hash_key = targetParent.children[targetIdx + 1].hash_key
        }
      }
      this.$axios.post(urls.update_docs, params)
        .then(res => {
          if (res.header.code == 0) {
            this.updateMenu()
            return true
          }
          this.$swal.showValidationMessage(res.header.msg || '网络错误，请稍后重试')
        })
        .catch(err => {
          this.$swal.showValidationMessage('网络错误，请稍后重试')
        })
    },
    bodyClick(e) {
      if (!this.show_dropdown) return
      let cls = e.target.className
      if (cls.indexOf('plus-dropdown') == -1 && cls.indexOf('dropdown-item') == -1
        && cls.indexOf('icon-plus') == -1) {
        this.show_dropdown = false
      }
    },
    leaveDropdown() {
      let t = setTimeout(() => {
        clearTimeout(t)
        this.show_dropdown = false
      }, 1000);
    },
    handleOperate(type) {
      let menu = this.curr_click_menu
      let pos = this.curr_click_pos
      // 1 新增子节点    2 新增兄弟节点
      if (this.is_editing_new) {
        return
      }
      this.is_editing_new = true
      let obj = {
        pid: type == 1 ? menu.id : menu.pid,
        title: '',
        model_id: menu.model_id,
        is_new: true,
        children: [],
        prev_id: -1
      }
      let iis = pos.split('-')
      let target = this.menus
      let targetParent = this.menus
      let len = iis.length
      let targetIdx = +iis[len - 1]
      iis.forEach((idx, j) => {
        let i = +idx
        target = j == 0 ? target[i] : target.children[i]
        if (j < len - 1) {
          targetParent = j == 0 ? targetParent[i] : targetParent.children[i]
        }
      })
      
      if (type === 1) {
        target.children.splice(targetIdx + 1, 0, obj)
      } else {
        let t = type === 2 ? targetIdx : targetIdx + 1

        if (len == 1) {
          if (type === 2) {
            obj.prev_id = target.id
          } else {
            if (targetIdx < targetParent.length - 1) {
              obj.prev_id = targetParent[targetIdx + 1].id
            }
          }
          targetParent.splice(t, 0, obj)
        } else {
          if (type === 2) {
            obj.prev_id = target.id
          } else {
            if (targetIdx < targetParent.children.length - 1) {
              obj.prev_id = targetParent.children[targetIdx + 1].id
            }
          }
          targetParent.children.splice(t, 0, obj)
        }
      }
      this.curr_doc.pid = obj.pid
      this.curr_doc.title = ''
      this.curr_doc.content = ''
      this.curr_doc.prev_id = obj.prev_id
      this.setEditorValue()
    },
    // 新建 菜单&文档 - 菜单标题输入事件
    newMenuInputChange(title) {
      let trimd = title.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/, '')
      this.curr_doc.title = trimd
    },
    // 模块下无菜单文档时 创建第一个一级文档节点
    createFirstMenu() {
      this.menus = [
        {
          is_new: true,
          title: '',
          pid: 0,
          model_id: this.curr_model,
          children: []
        }
      ]
      this.is_editing_new = true
    },
    async getModels(flag) {
      let { header, data } = await this.$axios.get(urls.get_models)

      if (header.code == 0 && Array.isArray(data)&&data.length>0) {
        this.models = data
        this.curr_model = data[0].model_id
      }else{
        this.models=[]
        this.curr_model=''
      }
      if(flag!='onlyEdit'){
        this.updateMenu()
      }
    },
    async updateMenu() {
      let params = { model_id: this.curr_model }
      if(!this.curr_model){
        this.menus = []
        this.menu_loaded = true
        return 
      }
      const { header, data } = await this.$axios.get(urls.get_menus, params)
      if (header.code == 0 && Array.isArray(data) && data.length > 0) {
        this.menus = [...data]
        this.curr_hash_key = this.menus[0].hash_key||''
        this.getDoc()
      } else {
        this.menus = []
        this.curr_hash_key = ''
        this.curr_doc.content = ''
        this.curr_doc.title = ''
        this.curr_doc.pid = ''
        this.curr_doc.prev_id = ''
        this.setEditorValue()
      }
      this.menu_loaded = true
    },
    async getDoc() {
      if (!this.curr_hash_key) {
        return
      }
      this.loading = true
      this.loaded = false
      let params = { hash_key: this.curr_hash_key }
      const { header, data } = await this.$axios.get(urls.get_docs, params)
      if (header.code == 0 && data) {
        let { title, updated_at, content } = data
        let md = unescape(content)
        // 这一块要做一个兼容模式，如果是html格式的话，就不用转了，
        // 实验
        let html = marked(md)
        html = html.replace(/<img src="(http:?)\/\/orion-base-test/g,'<img style="width:100%" src="https://orion-base-test')
        // html = html.replace(/<img/g,'<img style="width:100%" ')

        this.curr_doc = {
          title,
          updated_at,
          content: html
        }
        this.$refs.vueTinymce.isShow = false
      } else {
        this.curr_doc = {
          title: '',
          updated_at: '',
          content: ''
        }
      }
      this.loading = false
      this.loaded = true
      this.setEditorValue()
      setTimeout(()=>{
        this.$refs.vueTinymce.isShow = true
      },200)

    },
    setEditorValue() {
      // this.vditor.setValue(this.curr_doc.content, true)
    },
    confirm(title, text, icon) {
      let swal = this.$swal({
        title: title,
        text: text || '',
        icon: icon || 'info',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定'
      })
      return new Promise(function(resolve, reject) {
        swal.then((res) => {
          if (res.value) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    async saveDoc() {
      let type = this.is_editing_new ? 1 : 2
      if (type == 1 && this.curr_doc.title == '') {
        this.$swal({
          toast: true,
          text: '请填写新建文档标题',
          timer: 2000
        })
        return
      }
      if (type == 2 && !this.curr_hash_key) {
        this.$swal({
          toast: true,
          text: '请先选择要修改的文档后操作',
          timer: 2000
        })
        return
      }
      let content = tinymce.activeEditor.getContent()
      let html = tinymce.activeEditor.getContent()
      if (html.length == 0) {
        this.$swal({
          toast: true,
          text: '请填写文档内容',
          timer: 2000
        })
        return
      }
      content = escape(content)
      let url = urls.create_docs
      let confirm_title = '确定要新建此文档吗？'
      let params = {
        title: this.curr_doc.title,
        content,
        model_id: this.curr_model + ''
      }
      // 更新
      if (type == 2) {
        params.hash_key = this.curr_hash_key
        url = urls.update_docs
        confirm_title = '确定要更新此文档吗？'
      } else {
        params.pid = this.curr_doc.pid
        params.prev_id = this.curr_doc.prev_id
      }
        let confirmd = await this.confirm(confirm_title)
      if (!confirmd) return
      const { header, data } = await this.$axios.post(url, params)
      let pass = this.resolveNoPass(header.code)
      if (!pass) return
      if (header.code == 0) {
        let tip = type == 1 ? '创建成功' : '更新成功'
        this.$swal({
          ...ALERT_OPTION,
          text: tip,
          timer: 500
        })
        this.updateMenu()
        this.is_editing_new = false
      } else {
        this.$swal({
          toast: true,
          text: header.msg,
          timer: 2000
        })
      }
    },
    async delDoc(menu) {
      let params = { id: menu.id }
      const { header, data } = await this.$axios.get(urls.del_doc, params)
      let pass = this.resolveNoPass(header.code)
      if (!pass) return
      if (header.code == 0) {
        this.$swal({
          ...ALERT_OPTION,
          text: '删除成功',
          timer: 500
        })
        this.updateMenu()
      } else {
        this.$swal({
          toast: true,
          text: header.msg,
          timer: 2000
        })
      }
    },
    resolveNoPass(code) {
      if (code == 405) {
        this.$swal({
          toast: true,
          text: '没有权限',
          timer: 2000
        })
        Cookie.remove('__cd_vt')
          this.signed = false
        return false
      }
      return true
    },
    initEditor() {
      let that = this
      let con = this.content
      let { apiVer, baseUrl } = this.$envConfig
      let config = {
        theme: 'ob-doc',
        cache: {
          enable: false
        },
        toolbar: ["headings", "bold", "italic", "strike", "link", 
          "list", "ordered-list", "outdent", "indent", 
          "quote", "line", "code", "inline-code", "insert-before", "insert-after", 
          "table", "upload", "emoji", 
          "undo", "redo",
        ],
        tab: '\t',
        mode: 'wysiwyg',
        upload: {
          accept: '.jpg,.jpeg,.png,.gif,.svg+xml',
          url: `${baseUrl + apiVer}/cn/upload_file`,
          headers: {
            authorization: this.token
          },
          success(vditor, body) {
            let res = JSON.parse(body)
            let { header, data } = res
            if (header.code == 0 && data) {
              let files = data
              if (!Array.isArray(files)) {
                files = [files]
              }
              files.forEach(async (item) => {
                let { fileName, url, type } = item
                let fileType = that.checkFileType(type)
                let reg = /^https:\/\//
                let src = reg.test(url) ? url : 'https://' + url.replace('http://','')
                let node = '',md=''
                switch(fileType) {
                  case 'image':
                    node = `<img src=${src} alt="${fileName}">`
                    md=`![${fileName}](${src})`
                    break
                  // case 'video':
                  //   // const previewElement = document.getElementById('preview')
                  //   // const html = await thit.Vditor.md2html(src)
                  //   node = `<video src="${src}" controls controlsList="nodownload" oncontextmenu="return false" alt="${fileName}"/>`
                  //   // previewElement.innerHTML = html;
                  //   that.vditor.mediaRender(node)
                  //   // md=`<video id="video" controls="" preload="none" poster="http://img.blog.fandong.me/2017-08-26-Markdown-Advance-Video.jpg"><source id="mp4" src="${src}" type="video/mp4"></video>`
                  //   break
                  // case 'audio':
                  //   node = `<audio src="${src}" controls controlsList="nodownload" oncontextmenu="return false" alt="${fileName}"/>`
                  //   break
                  default:
                    node = `<a href="${src}" target="_blank">${fileName}</a>`
                    md= '暂时仅支持图片上传'
                }
                // that.vditor.mediaRender(node)
                that.vditor.insertValue(md)
              })
            }
          }
        },
        focus: function() {
          that.editor_focus = true
        },
        blur: function() {
          that.editor_focus = false
        },
        after: function() {
          // that.setEditorValue()
        }
      }

      // this.vditor = new Vditor('ob-editor', config)
    },
    checkFileType(str) {
      if (str.indexOf('image/') > -1) {
        return 'image'
      }
      if (str.indexOf('video/') > -1) {
        return 'video'
      }
      if (str.indexOf('audio/') > -1) {
        return 'audio'
      }
      return 'other'
    },
    async publish() {
      let confirmd = await this.confirm('确定要发布到线上吗？')
      if (!confirmd) return
      let { header, data } = await this.$axios.get(urls.publish)
      let pass = this.resolveNoPass(header.code)
      if (!pass) return
      if (header.code == 0) {
        this.$swal({
          ...ALERT_OPTION,
          text: '发布完成!',
          timer: 500
        })
      } else {
        this.$swal({
          toast: true,
          text: header.msg,
          timer: 2000
        })
      }
    }
  }
}
</script>

<style lang="less">
@import url("~style/style/custom-editor.less");
@import url("~style/style/btns.less");
@bgGray: rgba(244, 245, 246, 1);
@borderGray: rgba(234, 234, 234, 1);
@blue: #6976FF;
.doc-write {
  .model-wrap {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 78px;
    line-height: 78px;
    text-align: center;
    background: @bgGray;
    border-top: 1px solid @borderGray;
      .model-wrap-scroll{
          box-sizing: border-box;
          margin-left: 255px;
          margin-right: 250px;
          overflow-x: overlay;
          white-space: nowrap;
          height: 79px;
          line-height: 79px;
      }
    .md-btn {
      position: relative;
      display: inline-block;
      margin: 0 5px;
      // width: 102px;
      min-width: 78px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding: 0 24px;
      user-select: none;
      cursor: pointer;
      color: #2E2E2E;
      background: rgba(255, 255, 255, 1);
      text-align: center;
      border-radius: 4px;
      transition: all .2s;
      &.active {
        color: #fff;
        background: @blue;
      }
      &:not(.active):hover {
        background: rgba(255, 255, 255, .4);
      }
      .icon-edit{
        display:none;
        position: absolute;
        top: -7px;
        right: 2px;
      }
    }
    .md-btn:hover .icon-edit{
      display: block;
    }
    
  }
  .select-language{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 190px;
    top: 19px;
    height: 38px;
    line-height: 38px;
    cursor: pointer;
    transition: opacity .3s;
    span{
      width: 44px;
      margin: 0 2px;
      display: inline-block;
    }
    span:hover{
      width: 44px;
      height: 30px;
      background: #DFDFDF;
      border-radius: 16px;
      line-height: 30px;
    }
    i{
      font-style:normal;
      color: #dedede;
    }
    .cn{
      font-size: 14px;
    }
    .en{
      font-size: 15px;
    }
  }
  .add-model-btn {
    position: absolute;
    right: 50px;
    top: 19px;
    width: 110px;
    height: 38px;
    line-height: 38px;
    border: 1px dashed #262626;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity .3s;
    opacity: .6;
    &:hover {
      opacity: 1;
    }
  }
  .wrt-logo {
    position: absolute;
    left: 25px;
    top: 21px;
    width: 187px;
    cursor:pointer
  }
  .doc-menus, .operation-bar {
    position: fixed;
    top: 79px;
  }
  .operation-bar {
    right: 50px;
    top: 100px;
    z-index: 20;
  }
  .doc-menus {
    bottom: 0;
    left: 0;
    width: 255px;
    box-sizing: border-box;
    padding: 16px 0 30px;
      background: #E5EAF3;
    overflow: auto;
    .txt-btn {
      text-decoration: underline;
      cursor: pointer;
    }
    .menu-item {
      min-width: 200px;
    }
  }
  .plus-dropdown {
    position: fixed;
    right: -80px;
    top: 170px;
    width: 80px;
    z-index: 30;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    .dropdown-item {
      padding: 5px 12px;
      font-size: 13px;
      color: #262626;
      &:hover {
        background: rgba(105, 118, 255, .1);
      }
      &:first-of-type {
        border-radius: 4px 4px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }
    }
  }
  .doc-container {
    background: #fff;
    margin-left: 255px;
    margin-right: 250px;
    padding-top: 80px;
    // padding: 145px 0 150px;
    box-sizing: border-box;
    .doc-title {
      margin-bottom: 30px;
      padding-bottom: 26px;
      border-bottom: 1px solid @borderGray;
      h1 {
        font-size: 32px;
        line-height: 1.4;
        margin-bottom: 10px;
        color: #222222;
        white-space: normal;
        word-break: break-all;
      }
      .sub-word {
        font-size: 14px;
        line-height: 14px;
        color: #A3A3A3;
      }
    }
  }
  .my-editor {
    border: 0;
    .vditor-toolbar {
      border: 0;
      border-top: 1px solid @borderGray;
      box-shadow: 0 1px 1px rgba(128, 128, 128, 0.2);
      position: fixed;
      top: 79px;
      left: 0;
      right: 0;
      z-index: 20;
      background: @bgGray;
      padding-left: 371px !important;
    }
    .vditor-toolbar__item {
      .vditor-tooltipped {
        width: 35px;
        &[data-type="upload"] svg {
          width: 18px;
          height: 18px;
          margin-top: -1px;
        }
      }
    }
    .vditor-content {
      min-height: calc(100vh - 295px);
    }
    .vditor-wysiwyg pre.vditor-reset {
      padding: 0 0 0 80px !important;
    }
    .vditor-wysiwyg pre.vditor-reset:focus {
      background: transparent;
    }
  }

  .lg-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #673AB7 50%, #6231b9 50%);
    .lg-bg {
      padding: 50px 60px;
      background: rgba(41, 5, 105, 0.43);
      > div:last-of-type {
        margin-top: 50px;
      }
    }
    input {
      display: block;
      box-sizing: border-box;
      width: 300px;
      height: 50px;
      outline: none;
      border: 0;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, .7);
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 30px;
      padding-left: 20px;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-transition-delay: 99999s;
        -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
      }
      
      &:focus {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
      }
    }
    button {
      border: 0;
      outline: 0;
      width: 300px;
      height: 50px;
      box-sizing: border-box;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      background: #6439af;
      &:hover {
        background: #8a2be2;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.58);
      }
    }
  }
}
.cn-modal {
  .swal2-popup {
    padding: 30px;
  }
  .swal2-input {
    height: 46px;
  }
  .swal2-validation-message {
    margin: 0 !important;
    transition: .3s;
  }
}
</style>
