<template>
 <div id="doc-app">
      <!-- <div class="toolbar" @click='fn'>点击切花</div> -->
      <div class="box">
          
        <div class="tit"><input v-model='title' type="text" placeholder="请输入标题……" /></div>
        <!-- <div class="doc-cnt" id="tinydemo-doc"></div> -->
            <!-- :key='title' -->
        <vue-tinymce
            v-if="isShow"
            class="doc-cnt"
            v-model="content"
            :setting='setting'
            :setup='setup'
            ref="tinymce"

        >
        </vue-tinymce>
      </div>
 </div>
</template>

<script>
import 'tinymce/plugins/lists' // 列表插件
export default {
    props:{
        currentContent:Object,
    },
    watch:{
       currentContent(val){
           this.updateDoc(val)
       } ,
        title(val,oldval){
            this.$emit('changeTitle',val)
        }
    },
 data() {
    let { apiVer, baseUrl } = this.$envConfig
    let _this = this;
    return {
        a:0,
      isShow:true,
        title:'',
        content:'',
        setting:{  
            // selector:'#tinydemo-doc',
            // inline:true,  
            language: 'zh_CN', //本地化设置
            //  toolbar: false, //隐藏工具栏
            menubar: false, //隐藏菜单栏 
            branding: false, // 隐藏右下角技术支持
            // statusbar: false, // 隐藏左下角的状态栏
            content_style: "img {max-width:100%;}", // 设置编辑区内的样式
            // toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat | codesample | code | save restoredraft | preview",


            plugins: 'preview searchreplace autolink image link media codesample table charmap hr pagebreak nonbreaking anchor advlist lists textpattern codesample save quickbars autosave ',
            // plugins:'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs',
            // emoticons  
            // emoticons 
            // bdmap  有问题
            // indent2em 有问题
            // formatpainter  有问题
            // axupimgs 有问题

            // charmap hr pagebreak nonbreaking anchor advlist lists textpattern help emoticons bdmap indent2em formatpainter axupimgs quickbars autosave codesample save
            toolbar: 'undo redo restoredraft | cut copy | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify | numlist bullist blockquote codesample save | \
                    formatselect fontselect fontsizeselect | \
                    table image media hr preview',
            // toolbar:'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
            //          styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
            //          table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',

            // formatpainter  emoticons
            //subscript superscript removeformat
            // fixed_toolbar_container:'#doc-app .toolbar',
            // custom_ui_selector: 'body',
            placeholder:'写点什么....',
            toolbar_mode : 'wrap',
            importcss_append: true,
            toolbar_sticky: true,
            autosave_ask_before_unload: false,
            fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',

            font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
            // images_upload_base_path: '/demo',
            // images_upload_handler: function (blobInfo, succFun, failFun) {
            //     succFun('/demo/images/img.jpg');
            // },
            init_instance_callback: function(editor){
                $('#doc-app').fadeIn(1500);
                editor.execCommand('selectAll');
                editor.selection.getRng().collapse(false);
                editor.focus();
            },
            quickbars_selection_toolbar: "h1 h2 h3 fontsizeselect | bold italic underline forecolor backcolor | blockquote link bullist numlist alignleft aligncenter alignright",
            // quickbars_selection_toolbar: "h1 h2 h3 blockquote link | bold italic underline strikethrough | fontsizeselect forecolor backcolor ",
            quickbars_insert_toolbar: 'quickimage quicktable',
            // quickbars_insert_toolbar: 'alignleft aligncenter alignright'
            // images_upload_url:`${baseUrl + apiVer}/cn/upload_file`,

            // images_upload_credentials: true,

            images_upload_handler: async function (blobInfo, succFun, failFun) {
                var xhr, formData;
                var file = blobInfo.blob();//转化为易于理解的file对象
                const img = new FormData();
                img.append("file", file);
                let { header, data } = await _this.$axios.post('/cn/upload_file',img,{headers: {
                'Content-Type': 'multipart/form-data'},})
                if(header.code==0){
                    succFun(`https://${data.url}`);
                }else{
                    failFun(header.msg||'上传失败')
                }
            },

            //// 一会放开
            file_picker_types: 'file image media',
            file_picker_callback: (callback, value, meta)=> {
                this.uploadMedia(callback,value,meta)
            },



            // video_template_callback: function(data) {
            //     console.log('data',data);
            //     return '<video width="' + data.width + '" height="' + data.height
            //     + '"' + (data.poster ? ' poster="' + data.poster + '"' : '')
            //     + ' controls="controls">\n' + '<source src="' + data.source + '"'
            //     + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n'
            //     + (data.source ? '<source src="' + data.source + '"'
            //     + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n' : '')
            //     + '</video>';
            // },
            // media_scripts: [
            //     {filter: 'https://orion-base-test-1256573505.cos.ap-beijing.myqcloud.com/cn_docs_file/2021-03-17_11%3A58%3A50_WeChatSight113.mp4', width: 300, height: 200},
            // ],
            // media_url_resolver:function(data,resolve,reject){
            //     console.log('media_url_resolver',data);
            //     let medio = `<video width='${data.width}' height='${data.height}' controls controlslist='nodownload'> ${unescape(`<source src='${data.source}' type='${data.sourcemime}'>`)}</video>`
            //     let medio1 = `<source src='${data.source}' type='${data.sourcemime}'>`
            //     resolve({html:medio})
            // },

            
            
            
            // toolbar_drawer: "sliding",
            // quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor ",
            // plugins: "link image media table lists advlist fullscreen quickbars codesample code save autosave preview",
            codesample_languages: [
                {text: 'HTML/XML', value: 'markup'},
                {text: 'JavaScript', value: 'javascript'},
                {text: 'CSS', value: 'css'},
                {text: 'Java', value: 'java'},
                {text: 'PHP', value: 'php'},
                {text: 'mermaid', value: 'mermaid'},

            ],
            // paste_data_images:true,
            // forced_root_block: 'p',
            save_onsavecallback:function(editor){
                // console.log('content',tinyMCE,_this.content,_this.$tinymce.editors[0].getContent())
                // console.log('save_onsavecallback',editor)
                if(!editor)return
                let content = editor.getContent({
                                format: 'raw',
                                no_events: true
                                })
                _this.anotherContent = content
                // _this.anotherContent = editor.contentDocument.toString()
                // _this.anotherContent = editor.contentDocument.activeElement.innerHTML
                // console.log(editor,_this.anotherContent);

            },
            convert_urls: false,
            // codesample_content_css: '/public/prism-css-all.css',
     }
 }
 },
 created(){},
 mounted(){
    //  this.updateDoc()
    //  console.log(this.content);
    //  console.log(tinymce.init);
     this.init()
 },
 methods:{
        uploadMedia(callback,value,meta){

                var filetype='.pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4';

                switch(meta.filetype){
                    case 'image':
                        filetype='.jpg, .jpeg, .png, .gif';
                        break;
                    case 'media':
                        filetype='.mp3, .mp4';
                        break;
                    case 'file':
                    default:
                }
                //模拟出一个input用于添加本地文件
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', filetype);

                input.onchange = async (e)=> {
                    let file = e.path[0].files[0]
                    const fileData = new FormData();
                    fileData.append("file", file);
                    let { header, data } = await this.$axios.post('/cn/upload_file',fileData,{headers: {'Content-Type': 'multipart/form-data'},})
                    if(header.code==0){
                        callback(`https://${data.url}`);
                    }else{
                        // callback(header.msg||'上传失败')
                    }
                }
            input.click();

        },
     updateDoc(val){
        //  if(this.currentContent){
            // console.log(val.content);
        // this.content = val.content
        this.title = val.title
        // }
        this.content = val.content;
         tinymce.activeEditor.setContent(val.content)
        // let b = this.a++
        // console.log(b);
        // this.currentDoc.content =`<div>${b}</div>`
     },
     fn(){
         this.updateDoc()
     },
     init(){
         tinymce.init(this.setting)
     },
     setup(editor){
         // editor.on('input change undo redo execCommand KeyUp', function(e) {
         //     this.$emit('input', editor.getContent());
         // })
     }
},
 components: {

 }
}
</script>

<style lang="less">
    #doc-app{display:none;}
    #doc-app .toolbar {padding-bottom:10px;}
    #doc-app .tox .tox-toolbar{background:none;}
    #doc-app .tox-tinymce-inline .tox-editor-header{border:none;}
    #doc-app .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){border:none;}
    #doc-app .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type):after{border:none;}
    // #doc-app .box {padding:10px;border:1px solid #ddd;}
    #doc-app .tit{padding-bottom:10px;}
    #doc-app .tit input{outline:none;font-size:24px;padding:8px 8px;width:100%;box-sizing:border-box;border:none;box-shadow:none;background-color:#fefefe;color:#777;border-bottom:1px dotted #ccc;}
    // #doc-app .doc-cnt{
    //     min-height:300px;
    //     max-height:500px;
    //     // overflow:auto;
    //     font-size:16px;
    //     padding:0.5em;}
    #doc-app .doc-cnt:focus{outline:0;}
    #doc-app .doc-cnt p{text-indent:0;}
    #doc-app .doc-cnt::before{
            left: 9px;
            line-height: 1.3;
    }
    // #doc-app .box .tit .doc-cnt ol{padding-left: 20px;}
    // #doc-app .box .tit .doc-cnt ul{padding-left: 20px;}
    #doc-app{
        padding: 0 20px;
        .box{
                height: 88vh;
                padding:10px 10px 0;
            .tox-tinymce{
                height: 100% !important;
                padding-bottom: 65px;
            }
        .doc-cnt{
            min-height:300px;
            max-height:500px;
            // overflow:auto;
            font-size:16px;
            padding:0.5em;
                ul,ol{
                // padding-left: 20px !important;
                }
            }
            
        }
        .box{
            // position: relative;
            overflow: hidden;
            .tox.tox-tinymce{
                border: none;
                .tox-statusbar{
                    display: none;
                }
            }
            .tit{
                // position: absolute;
                // top: 91px;
                z-index: 1000;
                width: 100%;
                left: 12px;
            }
            .tox-sidebar-wrap{
                // margin-top: 50px;
                border: 1px dotted #ccc;
                border-radius: 4px;
            }
            img{
                width: 100% !important;
            }
        }
    }
    #tinymce{
        img{
             width: 100% !important;
        }
    }
</style>
